import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AppLoaderComponent } from './layout/app-loader/app-loader.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ToastService } from './service/toast.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { AppLoaderService } from './service/app-loader.service';
// import {IvyCarouselModule} from 'angular-responsive-carousel';

import { QuickViewComponent} from './quick-view/quick-view.component';
import { ReadMoreComponent } from './read-more/read-more.component';
 
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppLoaderComponent,
    HomePageComponent,
    ProductListComponent,
    ProductDetailComponent,
    QuickViewComponent,
    ReadMoreComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatRadioModule,
    MatTooltipModule,
    CarouselModule,
    MatSnackBarModule,
    MatDialogModule,
    // IvyCarouselModule
  ],
  providers: [ToastService,AppLoaderService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    AppLoaderService],

  // providers: [
  //   {
  //     provide: MatDialogRef,
  //     useValue: {}
  //   },
  //   DialogService
  bootstrap: [AppComponent]
})
export class AppModule { }
