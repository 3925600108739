
  <section class="quick-view">
    
  
    <div class="container-fluid">
        
  
       
        <div fxLayout="row wrap"  > 
          
          
      
              

            <div fxFlex="50" fxFlex.lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" lt-sm="100"
                fxFlex.xs="100" style="position: relative;">
                
            
             
             
                
             
             
                             
                <owl-carousel-o [options]="custom"  >
                   
                    <h1 style="position: absolute;" >qqqqqqq</h1>
             
                    <ng-template carouselSlide  *ngFor="let ima of images">
                        <img  src='{{ima?.image}}' alt="" >
                       
                    </ng-template> 
                   
  
              

                </owl-carousel-o>
           
                <i class="fa-solid fa-xmark mobile-close"  style="color: #ffffff; font-size: 18px;" (click)="closeDialog()" ></i>
                <!-- <img class="mobile-close" src="/assets/images/close-mob.png" (click)="closeDialog()" alt="" style=""> -->
  
          
        </div>
          
      
            
        
            <div fxFlex="50" fxFlex.lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" lt-sm="100"
                fxFlex.xs="100">
                <div class="details" >
                  
                   
                        <img  src="/assets/images/close.png" (click)="closeDialog()" alt="">
               
                    <h2>{{data?.product_name}}</h2>
                   <p #description></p>
                            <div class="action-buttons">
                                <button mat-raised-button class="primary-btn" (click)="detailView(data?.product_id)">View more</button>  <!--(click)="goActive()"-->

                            </div>
                    
                </div>
                
            </div>
        </div>
        </div>
        
 
</section>



    















<!-- 
<button mat-flat-button   (click)='goActive(productData)' >

view more




</button> -->

