<div class="header">
    <div class="row">
        <div class="menu">
            <a [routerLink]="'/home'"> <img src="assets/images/logo2.png" width="100%" /></a>
            <ul class="web">
                <li><a [routerLink]="'/home'" routerLinkActive="is-active">Home</a></li>
                <li><a href="#why-us"  routerLinkActive="is-active" >Why Buywoods</a></li>
                <li><a href="#product-sec" routerLinkActive="is-active">Products</a>
                </li>
                <li><a href="#enquiry-sec"  routerLinkActive="is-active">Contact us</a></li>
            </ul>
        </div>
        <div>
            <span class="toggle-btn" (click)="show = !show">
                <mat-icon>menu</mat-icon>
            </span>
            <ul class="web">
                <li><a href="#">info@buywoods.in</a></li>
                <li><a href="#">|</a></li>
                <li><a href="tel:+91 99 44 22 88 43 ">+91 99 44 22 88 43</a></li>
               
            </ul>

        </div>
    </div>
    <div *ngIf="show" style="background:white;transition: .5s ease;">
        <div class="mobile">
            <a [routerLink]="'/home'"> <img src="assets/images/logo1.png" width="40%" /><span class="toggle-btn"
                    (click)="show = !show">
                    <mat-icon>close</mat-icon>
                </span></a>
            <ul>
                <li><a [routerLink]="''" routerLinkActive="is-active">Home</a></li>
                <li><a (click)="scrollFn('why-us')">Why Buywoods</a></li>
                <li><a [routerLink]="'/products'" routerLinkActive="is-active">Products</a></li>
                <li><a (click)="scrollFn('enquiry-sec')">Contact us</a></li>
                <li>
                    <p><a href="#">Email-id : info@buywoods.in</a></p>
                    <p><a  href="tel:+91 99 44 22 88 43 ">Phone No :+91 99 44 22 88 43</a></p>
                    
            </ul>
        </div>
    </div>


</div>