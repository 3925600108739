<div class="homepage scroll-container">
    <section class="banner">
        <div class="container">
            <app-header></app-header>
            <h1 class="wow bounceInLeft ">Ensuring the best<br> Quality woods</h1>
            <p class="wow bounceInLeft ">The world of the timber is vast and confusing due to the lack of knowledge <br> 
                about timber, consumers often pays more and get less, but
                don't worry!! <br> we are here to solve the problem.
            </p>
            <a class="secondary-btn wow bounceInLeft  mt-60" (click)="scrollFn('enquiry-sec')">Enquiry Now</a>
        </div>
    </section>
    <!-- Why buywoods -->
    <section class="why-us" id="why-us">
        <div class="container">
            <div class="d-flex-center">
                <div class="why wow bounceInLeft ">
                    <ul>
                        <li style="list-style: none;" ><h3><b>Why Buywoods</b></h3></li>
                    </ul>
                     
                     <ul>
                         <li>
                            <p> One stop solution for all wood related questions.</p>

                         </li>
                    
                  
                 <li><p>It’s   a   professionally   managed   platform   for   knowledge   about   various woods</p></li>   
                    <li> <p>We   are   not   direct   seller   of   wood   products,   we   are   just   an   information provider.</p></li>
                    <li><p>We   continuously   update   information   and   prices   with   our   continuous research.</p></li>
                  <li> <p>We can also suggest suitable vendors on demand.</p></li> 
                   <li><p>Users can directly speak to wood expert to get additional information.</p></li> 
                </ul>

                    
                    
                </div>
                <div class="mission wow bounceInRight">
                    <img src="assets/images/hard-wood.png" width="35" height="35" /><span>Our Vision</span>
                    <p>Our Buywoods team is committed to ensure the happiness of our users by 
                        providing the right information, best price and suitable vendors by better 
                        understanding of the users’ requirements
                    </p>
                </div>
            </div>
            <div class="vision wow bounceInRight ">
                <img src="assets/images/hard-wood.png" width="35" height="35" /><span>Our Mission</span>
                <p style="margin-bottom: 40px !important;" >To become the most trusted platform in the country for all wood related queries 
                    and information by  providing the best solutions for wood related doubts
                </p>
            </div>
        </div>
    </section>
    <!--land and timber section-->
    <section class="land-section">
        <div class="container">
            <div class="text-left">
                <h1><b>Responsible Forestry.</b></h1>
                <h1><b>Quality Timber</b></h1>
            </div>

        </div>
    </section>
    <!-- <img src="assets/images/tree1.png" /> -->
    <!--Our Products-->
    <section class="product-section" id="product-sec">
        <div class="container">
            <div class="product-heading">
                <h1><b>Our Products</b></h1>
                <img src="assets/images/wood.png" width="25%" />
            </div>
            <p></p>
            <div class="product-list wow bounceInLeft ">
                <!-- <div class="product" *ngFor='let productData of homePageProduct' (click)='goActive(productData)'>
                    <img src="{{productData?.image}}" />
                    <h3>{{productData?.product_name}}</h3>
                    <div class="overlay">
                        <h3>{{productData?.wood_purpose_name}}</h3>
                        <h3 *ngIf="productData?.price_condition == 1">Price - ₹{{productData?.price_range_from}} -
                            ₹{{productData?.price_range_to}}
                        </h3>
                        <a>
                            <mat-icon>link</mat-icon>
                        </a>
                    </div>
                   
                </div> -->
                <!-- <div class="product" *ngFor='let productData of homePageProduct' (click)='goActive(productData)'> -->
         <div class="product" style="position: relative;" *ngFor='let productData of homePageProduct'>
                       
                    <img src="{{productData?.image}}"  (click)=" goActive(productData)"  />
                    <h3>{{productData?.product_name}}</h3>
                    <h3 *ngIf="productData?.price_condition == 1">Price - ₹{{productData?.price_range_from}} -
                        ₹{{productData?.price_range_to}}
                    </h3>
                    <!-- <h1 style="position: absolute; top: 0; right: 0;" >11</h1> -->
                    <i class="fa fa-arrows-alt" (click)="openDialog(productData)"></i>
                    
                    <!-- <div class="overlay">
                        <h3>{{productData?.wood_purpose_name}}</h3>
                        <h3 *ngIf="productData?.price_condition == 1">Price - ₹{{productData?.price_range_from}} -
                            ₹{{productData?.price_range_to}}
                        </h3>
                      
                         <h3 *ngIf="productData?.price_condition == 1">Price
                        </h3>
                        <app-quick-view *ngIf="hide" [payload]="payload" ></app-quick-view>
                         <button mat-raised-button  (click)="openDialog(productData)">Quick View</button>
                        <i class="fa-solid fa-arrows-maximize" style="height: 20px; width: 20px; background-color: red; position: absolute; top: 0; left: 0;"  (click)="openDialog(productData)" ></i>
                    </div>
                   -->
                </div>
                
            </div>
            <div class="text-center" *ngIf='homePageProduct?.length != 0'>
                <!--   -->
                <button class="primary-btn mt-60" [routerLink]="'/products'"(click)='forLoader()' >Show More</button>
            </div>
        </div>
    </section>
       <!---woods suitable foe me--->
       <section class="woods-suitable desktop">
        <div class="container">
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.gt-lg="100" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100"
                    fxFlex.xs="100" class="quiry-list wow bounceInLeft ">
                    <h1>Which type of wood is suitable for me ? </h1>
                    <h3  style="color: #7a5439 !important;" >  ❝ Good timber does not grow with ease, stronger the wind stronger the timber. ❞ </h3>
                   
                        <h4><b>Teak wood:</b> </h4>
                        <ul>
                            <li> <b>Burma Teak </b>   :</li>
                         
                                <li style="text-indent: 7%;">   Burma teak is the rich of the richest quality teak, government banned the import of 
                                    Burma teak in 2014 but big timber stores still have them as stock, products made out of this teak can 
                                    last long for centuries, the texture and color of this can mesmerize people due to its rich quality it also 
                                    cost more, Benin Teak also have the same property as Burma teak.
                                </li>
                        
                   
                   
                            <li> <b>Colombian Teak:</b></li>
                       
                                <li style="text-indent: 7%;" >Colombian teak is little cheaper than Burma teak , products made out of this teak can 
                                    also last long for centuries, but the texture and color of the teak will not be excellent, as Burma teak. </li>
                        
                       
                     
                            <li> <b>Nigeria Teak: </b> </li>
                         
                                <li style=" text-indent: 7%; " >Nigeria teak wood is white brown colored wood . Imported from the forests of Nigeria. They are low 
                                    on moisture , It has good quality but not a great texture and color of wood.</li>
                        
                     
                        
                            <li ><b>Panama Teak:</b></li>
                     
                                <li style=" text-indent: 7%; " >The qualities of Panama Teak is excellent. It The surfaces of the wood is very smooth and thick. 
                                    Furthermore, Panama Teak wood is highly resistant to insects, rots, termites, fire, and water.</li>
                       
                        
                        
                            <li> <b>Ghana Teak:</b> </li>
                  
                                <li style="text-indent: 7%; " >Ghana teak is the one of the strongest Teak Wood, It is decay resistant and dimensionally stable, Due  
                                    to its  high density and hardness of ghana teak. It is difficult to work with ghana teak. </li>
                          
                     
                            <li> <b>Mahogany:</b></li>
                           
                                <li style=" text-indent: 7%; " > We can not compare Mahogany with teak but mahogany wood known for its cheaper price 
                                    and acceptable quality, it is highly water resistant,  mahogany logs with big circumference can serve 
                                    us good quality of wood, it is easy to work with mahogany wood. </li>
                           
                     
                     
                            <li> <b>Vengai Wood:</b> </li>
                         
                                <li style="text-indent: 7%; " >  Vengai is also known as "Kwila” , in some parts of the world people consider it as "God's 
                                    Tree". Vengai is a dense hardwood with yellow brownish in color, it resists both rot and insect attacks. </li>
                            
                    
                      
                            <li> <b>Mango Wood:</b> </li>
                            
                                <li style="text-indent: 7%; " > Mango wood is a hard wood, it may turn black overtime,  mango wood is highly vulnerable to 
                                    fungal and insect attack, but it is a very durable wood, which is highly affordable. Chemical treatment 
                                    and seasoning must be done to avoid insect attacks.</li>
                         
                        
                    
                            <li> <b> Neem wood:</b></li>
                           
                                <li style="text-indent: 7%; " >     We can buy neem wood for very cheaper price, but we cannot expect quality, it is very 
                                    difficult to give a good polished look on neem wood, due to its rough texture caused by the 
                                    interlocking fibre.</li>
                          
                        
                    </ul>
                  
                </div>
              
            </div>
        </div>
    </section>
       <!---woods suitable foe me--->
       <!-- <section class="woods-suitable mobile-content">
        <div class="container">
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.gt-lg="100" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100"
                    fxFlex.xs="100" class="quiry-list wow bounceInLeft ">
                    <h1>Which type of wood is suitable for me :</h1>
                    <h3>  ❝ Good timber does not grow with ease, stronger the wind stronger the timber. ❞ </h3>
            <ul>
                        <li style="list-style: none;"><b style="color: #7a5439 !important;">Teak wood:</b> </li>
                   
                   
                            <li style="list-style: none;color:#885b3c;" >Burma Teak :</li>
                         
                                <li style="list-style: none; color:#885b3c;" > Burma teak is the rich of the richest quality teak, government banned the import of 
                                    Burma teak in 2014 but big timber stores still have them as stock, products made out of this teak can 
                                    last long for centuries, the texture and color of this can mesmerize people due to its rich quality it also 
                                    cost more, Benin Teak also have the same property as Burma teak.</li>
                           
                     
                      
                            <li style="list-style: none;color:#885b3c;" >Colombian Teak:  </li>
                         
                                <li style="list-style: none;color: #885b3c;" >Colombian teak is little cheaper than Burma teak , products made out of this teak can 
                                    also last long for centuries, but the texture and color of the teak will not be excellent, as Burma teak. </li>
                            
                     
                      
                            <li style="list-style: none;color:#885b3c;" >Nigeria Teak:  </li>
                            
                                <li style="list-style: none;color: #885b3c;" >Nigeria teak wood is white brown colored wood . Imported from the forests of Nigeria. They are low 
                                    on moisture , It has good quality but not a great texture and color of wood.</li>
                           
                    
                      
                            <li style="list-style: none;color: #885b3c;" >Panama Teak:   </li>
                            
                                <li style="list-style: none;color: #7a5c4c;" >The qualities of Panama Teak is excellent. It The surfaces of the wood is very smooth and thick. 
                                    Furthermore, Panama Teak wood is highly resistant to insects, rots, termites, fire, and water.</li>
                           
                     
                    
                            <li style="list-style: none;color:#885b3c;" >Ghana Teak:</li>
                           
                                <li style="list-style: none;color:#885b3c;" >Ghana teak is the one of the strongest Teak Wood, It is decay resistant and dimensionally stable, Due  
                                    to its  high density and hardness of ghana teak. It is difficult to work with ghana teak. </li>
                            
                      
                      
                            <li style="list-style: none" > <b style="color: #7a5439 !important;" >Mahogany:</b></li>
                       
                                <li style="list-style: none;color: #885b3c;" > we can not compare Mahogany with teak but mahogany wood known for its cheaper price 
                                    and acceptable quality, it is highly water resistant,  mahogany logs with big circumference can serve 
                                    us good quality of wood, it is easy to work with mahogany wood. </li>
                      
                     
                     
                            <li style="list-style: none" > <b style="color:#7a5439  !important;" > Vengai Wood:</b></li>
                     
                                <li style="list-style: none;color: #885b3c;" >  Vengai is also known as "Kwila” , in some parts of the world people consider it as "God's 
                                    Tree". Vengai is a dense hardwood with yellow brownish in color, it resists both rot and insect attacks. </li>
                   >
                    
                      
                            <li style="list-style: none; " > <b style="color: #7a5439 !important;" >Mango Wood:</b> </li>
                       
                                <li style="list-style: none;color: #885b3c;" > Mango wood is a hard wood, it may turn black overtime,  mango wood is highly vulnerable to 
                                    fungal and insect attack, but it is a very durable wood, which is highly affordable. Chemical treatment 
                                    and seasoning must be done to avoid insect attacks.</li>
                         
                      
                    
                            <li style="list-style: none" > <b style="color:#7a5439 !important;" > Neem wood:</b></li>
                       
                                <li style="list-style: none; color: #885b3c;" >     We can buy neem wood for very cheaper price, but we cannot expect quality, it is very 
                                    difficult to give a good polished look on neem wood, due to its rough texture caused by the 
                                    interlocking fibre.</li>
                            </ul>
                        
                   
                  
                </div>
              
            </div>
        </div>
    </section> -->
    <!--Wood Expert-->
    <section class="wood-expert">
        <div class="container">
            <div fxLayout="row wrap" class="row wow bounceInRight">
                <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100"
                    fxFlex.xs="100">
                    <h1><b>Our Experts</b></h1>
                    
                    <p>When we choose a wood for you , we do  it as,<br> we do it for ourselfes, and our choice will <br> make you <span
                        class="txt-color">happy...</span></p>
                    <!-- <p>When we build a house, we do <br>it as if for ourselfes, it makes <br> you <span
                            class="txt-color">happy</span></p> -->
                </div>
                <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100"
                    fxFlex.xs="100">
                    <img src="assets/images/wood-expert.png" alt="wood expert" width="50%" />
                </div>
            </div>
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor='let data of woodExperts; let i = index'>
                    <div class="wood-expert-details">
                        <img src="{{data?.photo}}" alt="user_img" /> 
                        <div class="">
                            <h2>{{data?.name}}</h2>
                            <h4 class="mb-4">{{data?.company_name != null ? data?.company_name : "-"}}</h4>
                            <!-- <p class="mb-0"> {{(readMore && i== readIndex) ? data?.about_the_employee :
                                data?.about_the_employee |
                                slice:0:100}} <span *ngIf="!readMore">...</span></p>
                            <a class="float-right" href="javascript:;" *ngIf="!(readMore && (i == readIndex) && data?.about_the_employee.length > 100)"
                                (click)="readMoreOption(i)" >Read More</a> -->
                                    <app-read-more 
                                    [content]="data?.about_the_employee"
                                    [limit]="100"
                                    [completeWords]="true"
                                    >
                                    </app-read-more>

                                    <!-- (data?.experience_year != null && data?.experience_year !=0 )
                                    && data?.experience_year !=0  -->

                                <!-- <a href="javascript:" *ngIf="(readMore && (i == readIndex) && data?.about_the_employee.length > 100)">Read Less</a> -->
                            <h4 class="mb-0 mt-12 text-grey">{{(data?.experience_year != null && data?.experience_year !=0 ) ? "Years of Experience" : ""}}</h4>
                            <h4 class="txt-black" *ngIf="(data?.experience_year != null  && data?.experience_year !=0)"><span >{{data?.experience_year}}
                                    <span>{{data?.experience_year > 1 ? 'years':'year'}}</span></span></h4>
                                   
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </section>
 

  
    <!--Enquiry section-->
    <section class="enquiry-sec" id="enquiry-sec">
        <div class="container">
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100"
                    fxFlex.xs="100" class="quiry-list wow bounceInLeft ">
                    <h1>Send Enquiry Now</h1>
                    <h3>By sending enquiry, you can able to know:</h3>
                    <ul>
                        <li>Know exact <b>price of each products</b> </li>
                        <li>Check <b>quality</b> of woods</li>
                        <li>Will help you to <b>find best products</b></li>
                        <li><b>24/7 live</b> Support</li>
                        <li>Know complete <b>details about wood</b></li>
                    </ul>
                </div>
                <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100"
                    fxFlex.xs="100" class="form-sec wow bounceInRight">
                    <mat-card>
                        <form [formGroup]='generalForm'>
                            <div>
                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>Name <span class="required_symbol">*</span></mat-label>
                                    <input matInput formControlName='name'>
                                    <span *ngIf="generalForm.controls['name'].hasError('pattern')
                         && generalForm.controls['name'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid name'"
                                        [matTooltipDisabled]="!generalForm.controls['name'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="generalForm.controls['name'].hasError('required')
                                        && (isSubmitted ||generalForm.controls['name'].touched) " matSuffix [matTooltip]="'Please enter the name'"
                                        [matTooltipDisabled]="!generalForm.controls['name'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>Mobile No <span class="required_symbol">*</span></mat-label>
                                    <input matInput formControlName='mobileNo'>
                                    <span *ngIf="generalForm.controls['mobileNo'].hasError('pattern')
                                && generalForm.controls['mobileNo'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid mobile number'"
                                        [matTooltipDisabled]="!generalForm.controls['mobileNo'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="generalForm.controls['mobileNo'].hasError('required')
                                    && (isSubmitted ||generalForm.controls['mobileNo'].touched)" matSuffix [matTooltip]="'Please enter the mobile number'"
                                        [matTooltipDisabled]="!generalForm.controls['mobileNo'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>Email Id</mat-label>
                                    <input matInput formControlName='email'>
                                    <span *ngIf="generalForm.controls['email'].hasError('email')
                                    && generalForm.controls['email'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid email'"
                                        [matTooltipDisabled]="!generalForm.controls['email'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="generalForm.controls['email'].hasError('required')
                                        && (isSubmitted ||generalForm.controls['email'].touched)" matSuffix [matTooltip]="'Please enter the email'"
                                        [matTooltipDisabled]="!generalForm.controls['email'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                                <mat-form-field class="w49" appearance="outline">
                                    <mat-label>State </mat-label>
                                    <mat-select formControlName='state'>
                                        <mat-option *ngFor="let state of allState" class="TextColor"
                                            [value]="state.state_id"
                                            (onSelectionChange)="getCity(state.state_id, $event?.isUserInput)">
                                            {{state.state_name}}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <span *ngIf="generalForm.controls['state'].hasError('required')
                                    && isSubmitted" matSuffix [matTooltip]="'Please select the state'"
                                        [matTooltipDisabled]="!generalForm.controls['state'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span> -->
                                </mat-form-field>
                            </div>

                            <div>
                                <mat-form-field class="pr-10 w49" appearance="outline">
                                    <mat-label>District </mat-label>
                                    <mat-select formControlName='district'>
                                        <mat-option *ngFor="let district of cities" class="TextColor"
                                            [value]="district.city_id">
                                            {{district.city_name}}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <span *ngIf="generalForm.controls['district'].hasError('required')
                                    && isSubmitted" matSuffix [matTooltip]="'Please select the district'"
                                        [matTooltipDisabled]="!generalForm.controls['district'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span> -->
                                </mat-form-field>

                                <mat-form-field class="w49" appearance="outline">
                                    <mat-label>Location </mat-label>
                                    <input matInput formControlName='Location'>
                                    <span *ngIf="generalForm.controls['Location'].hasError('pattern')
                                    && generalForm.controls['Location'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid location'"
                                        [matTooltipDisabled]="!generalForm.controls['Location'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <!-- <span *ngIf="generalForm.controls['Location'].hasError('required')
                                        && isSubmitted" matSuffix [matTooltip]="'Please enter the location'"
                                        [matTooltipDisabled]="!generalForm.controls['Location'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span> -->
                                </mat-form-field>
                            </div>

                            <div class="mt-30">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Notes</mat-label>
                                    <textarea matInput formControlName='notes'></textarea>
                                    <!-- <span *ngIf="generalForm.controls['notes'].hasError('required')
                                &&isSubmitted " matSuffix [matTooltip]="'Please enter the notes'"
                                        [matTooltipDisabled]="!generalForm.controls['notes'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span> -->
                                    <span *ngIf="generalForm.controls['notes'].hasError('pattern')
                      && generalForm.controls['notes'].touched" matSuffix [matTooltip]="'Please enter the valid notes'"
                                        [matTooltipDisabled]="!generalForm.controls['notes'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                            </div>
                            <div class="text-right">
                                <button type="button" class="primary-btn" (click)='create()'>Submit</button>
                            </div>
                        </form>
                    </mat-card>
                </div>
            </div>
        </div>
    </section>
    <div  class="whatsapp">
        <a  href="https://api.whatsapp.com/send?phone=+91 99 44 22 88 43&amp;text=Hello, Buywoods!"   target="_blank">
            <img src="assets/images/whatsapp1.png" width="140" height="140">
        </a>
    </div>
    <div  class="call-now">
        <a  href="tel:+91 99 44 22 88 43">
            <img src="assets/images/call-2.gif" width="120" height="120">
        </a>
    </div>


    <app-footer></app-footer>
</div>