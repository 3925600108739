import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../service/app-loader.service';
import { EnquiryService } from '../service/enquiry.service';
import { ToastService } from '../service/toast.service';

interface State {
  value: string;
  viewValue: string;
}
interface District {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  states: State[] = [
    { value: 'Tamil nadu', viewValue: 'Tamil nadu' },
    { value: 'Kerala', viewValue: 'Kerala' },
  ];
  districts: District[] = [
    { value: 'Madurai', viewValue: 'Madurai' },
    { value: 'Coimbatore', viewValue: 'Coimbatore' },
  ];
  id: string | null;
  productDetails: any;
  productName: any;
  woodForm: any;
  data: any;
  cities: any;
  allState: any;
  productList: any;
  isSubmitted: boolean=false;
  woods:boolean=true;
  products:boolean=true;
  @ViewChild('about') about !:ElementRef;
  @ViewChild('description') description !:ElementRef;
  @ViewChild('features')features!:ElementRef;
  @ViewChild('whichSuits') whichSuits!:ElementRef;
  @ViewChild('imageHeading')imageHeading!:ElementRef;
 




  constructor(private Actice: ActivatedRoute, private service: EnquiryService, private loader: AppLoaderService,
    private toastr: ToastService,
    private title :Title) {
    this.id = this.Actice.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.title.setTitle('Buywoods | Product-Detail')
    this.form();
    this.getProduct()
    this.listState();
    this.listProduct()
  }

  // Get One Product
  getProduct() {
    this.service.getOne(this.id).subscribe(res => {
      this.loader.open()
        if(res.keyword==='success'){
          this.loader.close()
        }
  
     
      this.productDetails = res?.data;
      if(this.productDetails.type==1){
        this.products=false;
        this.about.nativeElement.innerHTML=this.productDetails?.product_name;
        this.description.nativeElement.innerHTML=this.productDetails?.publish_description!=null ? this.productDetails?.publish_description :'-';
        this.features.nativeElement.innerHTML='Features';
        this.imageHeading.nativeElement.innerHTML='Wood Images';
        // console.log('yes its wood based product');
      }
      if(this.productDetails.type==2){
        this.woods=false;
        this.about.nativeElement.innerHTML=this.productDetails?.product_name;
        this.description.nativeElement.innerHTML=this.productDetails?.about_description!=null ? this.productDetails?.about_description :'-';
        this.features.nativeElement.innerHTML='Which woods are suitable for this product ?';
        this.whichSuits.nativeElement.innerHTML=this.productDetails?.which_woods_suit!=null? this.productDetails?.which_woods_suit:'-';
        this.imageHeading.nativeElement.innerHTML='Product Images';
        // console.log('yes its product based call');
      }
      console.log(this.productDetails ,'single product check');
      if(this.productDetails?.is_fee_applicable == 'Yes' && this.productDetails?.fee > 0){
        this.woodForm.get('isConsult').setValidators(Validators.requiredTrue)
        this.woodForm.get('isConsult').updateValueAndValidity()
      } else if(this.productDetails?.is_fee_applicable == 'No'|| this.productDetails?.fee < 0){
        this.woodForm.get('isConsult').clearValidators()
        this.woodForm.get('isConsult').updateValueAndValidity()
      }
      
    })
  }
  getProductNames() {
    this.service.getProductName().subscribe(res => {
      this.productName = res?.data
     
    })
  }



  listState() {
    this.service.getState().subscribe(res => {
      if (res.keyword == 'success') {
        this.allState = res.data
      }
    })
  }
  listProduct() {
    this.service.productList().subscribe(res => {
      if (res.keyword == 'success') {
        this.productList = res.data
        console.log(this.productList,'listProduct');
        
      }
    })
  }



  form() {
    this.woodForm = new FormGroup({
      name: new FormControl("", [
        Validators.required, Validators.pattern(/^\S$|^\S[\s\S]*\S$/)
      ]),
      mobileNo: new FormControl("", [
        Validators.required,
        Validators.pattern('^[6,7,8,9]{1}[0-9]{9}$'),
      ]),
      email: new FormControl("", [Validators.email]),
      state: new FormControl(''),
      district: new FormControl('', ),
      Location: new FormControl('', [ Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      woodName: new FormControl(''),
      notes: new FormControl('', [Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      isConsult: new FormControl('')
    });
  }
  create() {
    
    let forms = this.woodForm.value;
    console.log(forms);

    this.isSubmitted=true
    if (this.woodForm.valid) {
    console.log(forms);

      let formData = new FormData();
      formData.append('enquiry_type', '1');
      formData.append('name', forms.name);
      formData.append('email', forms.email == null ? '' : forms.email);
      formData.append('mobile', forms.mobileNo);
      formData.append('state_id', forms.state);
      formData.append('district_id', forms.district);
      formData.append('location',( forms.Location!=''&&  forms.Location!=null)? forms.Location:'');
      formData.append('enquiry_wood_name_id', this.productDetails?.product_id);
      formData.append('enquiry_purpose_notes', forms.notes != null ? forms.notes : '');
      formData.append('consult_wood_expert', forms.isConsult!=''?forms.isConsult:'');
      formData.append('consultation_fees', this.productDetails.fee);
    this.loader.open()
    this.isSubmitted=false
      this.service.woodCreate(formData).subscribe((result: any) => {
        this.data = result;
        if (this.data.keyword == "success") {
          this.loader.close()
          this.woodForm.reset();
          this.getProduct()
          this.toastr.present(this.data.message, "");
        } else {
          this.toastr.present(this.data.message, "");
        }
      });
    }else{
      
    }
    // else {
    //   Swal.fire({
    //     title: "Check Required",
    //     text: "Fill Mandatory Fields",
    //     icon: "warning",
    //   });
    // }

  }

  getCity(state_id: any, isUserInput: any) {
    if (isUserInput) {
      this.cities = [];
      this.woodForm.get('district')?.setValue('');
      this.woodForm.get('district')?.setValidators(Validators.required);
      this.woodForm.get('district')?.markAsUntouched();
      this.woodForm.get('district')?.updateValueAndValidity();
      this.service.getCities(state_id).subscribe((e: any) => {
        this.cities = e.data
      });
    }
  }





}
