import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EnquiryService} from '../service/enquiry.service'
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { ToastService } from '../service/toast.service';
import { AppLoaderService } from '../service/app-loader.service';
import { ViewportScroller } from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { QuickViewComponent } from '../quick-view/quick-view.component';
import { Title } from '@angular/platform-browser';


interface State{
  value: string;
  viewValue: string;
}
interface District{
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
  //  navText: ['🡨', '🡪'],
   navText: ['<img src="assets/images/left_arrow.png" />', '<img src="assets/images/right_arrow.png" />' ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      },
      1024: {
        items: 3
      }
    },
    nav: true
  }
  states: State[] = [
    {value: 'Tamil nadu', viewValue: 'Tamil nadu'},
    {value: 'Kerala', viewValue: 'Kerala'},
  ];
  districts: District[] = [
    {value: 'Madurai', viewValue: 'Madurai'},
    {value: 'Coimbatore', viewValue: 'Coimbatore'},
  ];

  data: any;
  generalForm!: FormGroup;
  allState: any;
  cities:any =[];
  productList: any;
  overAllProduct: any;
  homePageProduct: any;
  woodExperts: any;
  isSubmitted=false
  readMore=false;
  readIndex: any;
  aboutEmployee: any;
  oneProductData:any;
  payload :any;
  hide:boolean=false;
   content!: string;
   limit: number=200;
   completeWords!: boolean;

  //  readmore
  isContentToggled !: boolean;
  nonEditedContent ! : string;


  constructor(
    private modelService:EnquiryService,
    private Router:Router,
    private toast:ToastService,
    private loader:AppLoaderService,
    private _vps: ViewportScroller,
    private dialog: MatDialog,
    private title :Title
    ) {
  
   }

  ngOnInit(): void {
    this.title.setTitle('Buywoods | Home')
    this.form();
    this.listState();
    this.listProduct();
    this.OverallProductList();
    this.WoodExpertList();
    this.InitialLoad()
  }
  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
}
  listState(){
    this.modelService.getState().subscribe( res =>{
      if(res.keyword == 'success'){
        this.allState=res.data
      }
    })
  }

  listProduct(){
    this.modelService.productList().subscribe( res =>{
      if(res.keyword == 'success'){
        this.productList=res.data
      }
    })
}
readMoreOption(i:any){
  console.log(i);
  this.readIndex=i
  this.readMore=true
}
InitialLoad(){
  let formData= new FormData();
  formData.append('page_type', 'home_page')
  this.modelService.initialLoad(formData).subscribe( res =>{
    if(res.keyword == 'success'){

    }
  })
}
  WoodExpertList(){
    this.modelService.getWoodExpert().subscribe( res =>{
      if(res.keyword == 'success'){
        this.woodExperts=res.data;
        this.content=res.data
       
        console.log( this.aboutEmployee,"adsc");
        
      }
    })
  }
  
 OverallProductList(){
  // this.loader.open()
   this.modelService.getProducts('').subscribe( res =>{
    if(res.keyword==='success'){
      // this.loader.close()
      this.overAllProduct=res.data
    }
    this.homePageProduct=res?.data.slice(0,6)
    console.log( this.homePageProduct,'abc');
    
   })

   
 }



 
 goActive(Data:any){
   window.scroll(0,0)
this.loader.open()
  this.modelService.getOne(Data.product_id).subscribe((res:any)=>{
    if(res.keyword==='success'){
      this.loader.close()
      this.dialog.closeAll
      this.Router.navigateByUrl(`product-detail/${Data?.product_id}`)
      let formData= new FormData();
      formData.append('product_id', Data?.product_id)
      this.modelService.topProductSeen(formData).subscribe( res =>{
          
      })
    }
  })
}
  form(){
    this.generalForm = new FormGroup({
      name: new FormControl("", [
        Validators.required, Validators.pattern(/^\S$|^\S[\s\S]*\S$/)
      ]),
      mobileNo: new FormControl("", [
        Validators.required,
        Validators.pattern('^[6,7,8,9]{1}[0-9]{9}$'),
      ]),
      email: new FormControl("", [Validators.email]),
      state: new FormControl('',[Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      district: new FormControl('',[ Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      Location: new FormControl('',[ Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      notes: new FormControl('',[Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
     
    });
  }




 create() {
  let forms= this.generalForm.value;
  console.log(forms);
 this.isSubmitted=true
  if (this.generalForm.valid) {
    let formData = new FormData();
   formData.append('enquiry_type', '3');
   formData.append('product_id','');
   formData.append('name',forms.name);
   formData.append('email',(forms.email == null || forms.email =='' )? '': forms.email);
   formData.append('mobile',forms.mobileNo);
   formData.append('state_id',forms.state);
   formData.append('district_id', forms.district);
   formData.append('location',(forms.Location!=''&& forms?.Location!=null) ? forms.location :'');
   formData.append('enquiry_wood_name_id',forms.woodName ? forms.woodName : '' );
   formData.append('enquiry_purpose_notes', forms.notes != null ? forms.notes: '');
   formData.append('consult_wood_expert',forms.isConsult!=''?forms.isConsult:'');
   formData.append('consultation_fees',forms.ConsultFees == null ? '': forms.ConsultFees);
   this.loader.open()
   this.isSubmitted=false
    this.modelService.create(formData).subscribe((result: any) => {
        this.data = result;
      if (this.data.keyword ==="success") {
        this.loader.close()
        this.toast.present(this.data?.message)
        this.generalForm.reset()
      } else {
        this.loader.close()
        this.toast.present(this.data?.message)
      }
    });
  } 
  //   else {
  //     Swal.fire({
  //       title: "Check Required",
  //       text: "Fill Mandatory Fields",
  //       icon: "warning",
  //     });
  // }

}

getCity(state_id:any,isUserInput:any) {
  if (isUserInput) {
    this.cities = [];
    this.generalForm.get('district')?.setValidators(Validators.required);
    this.generalForm.get('district')?.setValue('');
    this.generalForm.get('district')?.markAsUntouched();
    this.generalForm.get('district')?.updateValueAndValidity();
    this.modelService.getCities(state_id).subscribe((e: any) => {
      this.cities = e.data
    });
  }
}
openDialog(data:any) {
this.loader.open();
this.modelService.getOne(data.product_id).subscribe((res:any)=>{
  if(res.keyword==='success'){
    this.loader.close()
    this.payload=data;
    const dialogRef = this.dialog.open(QuickViewComponent,{
      width:'77%',
      panelClass:'slider',
      hasBackdrop: true,
      data :this.payload
    });
  }
})
 
}

toggleContent(){

}

forLoader(){
  window.scroll(0,0)
  this.loader.open()
   this.modelService.getProducts('').subscribe( res =>{
    if(res.keyword==='success'){
      this.loader.close()
      this.overAllProduct=res.data
    }
   })

}

}