import { AfterViewInit, Component,ElementRef,Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnquiryService} from '../service/enquiry.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppLoaderService } from '../service/app-loader.service';
// import { Router } from '@angular/router';



@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit ,AfterViewInit{

  custom: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
  //  navText: ['🡨', '🡪'],
   navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>' ],
 
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
      1024: {
        items: 1
      },
      2056:{
        items: 1
      },
      2560:{
        items: 1
      },
      3160:{
        items: 1
      },
      4200:{
        items: 1
      },





    },
    
  }
  // @Input('payload') payload!:any;
  @ViewChild('description') description !:ElementRef;
  // dots:boolean=true
  
images:any=[]
  constructor(
  @Inject(MAT_DIALOG_DATA)  public data :any,
    private Router:Router,
    private modelService:EnquiryService,
    private dialog: MatDialog,
    private api :EnquiryService,
    private loader :AppLoaderService) { }

  ngOnInit(): void {
    // this.loaderFunction()
    this.getoneProduct()
  // console.log(this.data,'this is found in the dialogue ');
  }

  nativeMethod(){
    if(this.data?.type==1){
      let txt=  this.data?.publish_description.substr(0, 250).lastIndexOf(' ')
      this.description.nativeElement.innerHTML=this.data?.publish_description!=null ? this.data?.publish_description.substr(0, txt)+'.....':'-'
    }
    if(this.data?.type==2){
       let txt =this.data?.about_description.substr(0, 250).lastIndexOf(' ')
      this.description.nativeElement.innerHTML=this.data?.about_description!=null ? this.data?.about_description.substr(0, txt)+'.....':'-';
    }
  }

  getoneProduct(){

this.api.getOne(this.data.product_id).subscribe((res:any)=>{
 if(res.keyword=='success'){
  this.images=res.data.images;   

 }
})

    
  }
  
  goActive(Data:any){
  window.scroll(0,0)
    this.Router.navigateByUrl(`product-detail/${Data?.product_id}`)
  
    
  }
 
 
  closeDialog(){
    this.dialog.closeAll()
  }

  detailView(id:any){
    window.scroll(0,0)
this.loader.open()
 this.modelService.getOne(id).subscribe((res:any)=>{
if(res.keyword==='success'){
  this.loader.close()
  this.dialog.closeAll()
  this.Router.navigateByUrl(`product-detail/${id}`);
  let formData= new FormData();
  formData.append('product_id', id)
  this.api.topProductSeen(formData).subscribe( res =>{
  })
  console.log(id);
}
 

 })

  

    
  }


  ngAfterViewInit(): void {
    this.nativeMethod()
    }
 

}
