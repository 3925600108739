import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent} from '../layout/app-loader/app-loader.component'
@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  constructor(private dialog: MatDialog,
   private dialogRef: MatDialogRef<AppLoaderComponent>
  ) {
    
   }
  
  public open(title: string = ''): Observable<boolean> {
    // this.userService.isScrollToTop.next(true);
    this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop', panelClass : 'loader-custom-class' });
    // this.dialogRef.updateSize('200px');
    this.dialogRef.componentInstance.title = title;
    return this.dialogRef.afterClosed();
  }

  public close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
