<!--Footer-->
<div class="footer">
    <div class="container">
        <div class="d-flex-center" fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100">
                <span class="copyright">Copyright © 2021 Buywoods.in</span>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="text-right">
                <ul>
                    <li>Privacy Policy</li>
                    <li>Terms of Use</li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>