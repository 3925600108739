<div class="homepage productpage">
    <!--Banner-->
    <section class="banner">
        <div class="container">
            <app-header></app-header>
            <h1 class="wow bounceInLeft">Products</h1>
            <p class="wow bounceInLeft">
               
            </p>
        </div>
    </section>
    <!--Product List-->
    <section class="product-list">
        <div class="container">
            <div class="search-sec">
                <h1>{{OverallProductCount}} Products</h1>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Search products "
                        (keyup)='SearchInput($event)'>
                    <mat-icon matPrefix>search</mat-icon>
                    <!-- <input matInput  id="filterInput" 
                    placeholder="Search"  /> -->
                </mat-form-field>
            </div>
            <div class="products"  >
                <div class="product" *ngFor='let data of OverallProduct' style="position: relative;"  >
                    <img src="{{data?.image}}" class="wow swing center" (click)="goActive(data)" />
                    <h3>{{data?.product_name}}</h3>

                    <!-- <div class="overlay" (click)="openDialog(data)">
                        <button mat-raised-button  (click)="openDialog(data)">Quick View</button>
                    </div> -->
                    <i class="fa fa-arrows-alt"  (click)="openDialog(data)"></i>
                </div>
                <div *ngIf="OverallProduct == ''">
                    <p>No data found</p>
                </div>
            </div>
        </div>
    </section>
    <div  class="whatsapp">
        <a  href="https://api.whatsapp.com/send?phone=+91 99 44 22 88 43&amp;text=Hello, Buywoods!" target="_blank">
            <img src="assets/images/whatsapp1.png" width="140" height="140">
        </a>
    </div>
    <div  class="call-now">
        <a  href="tel:+91 99 44 22 88 43">
            <img src="assets/images/call-2.gif" width="120" height="120">
        </a>
    </div>
    <app-footer></app-footer>
</div>