<div class="homepage product-detail-page">
    <!--Banner-->
    <section class="banner">
        <div class="container">
            <app-header></app-header>
            <h1 class="wow bounceInLeft">{{productDetails?.product_name}}</h1>
            <p class="wow bounceInLeft">
            </p>
        </div>
    </section>
    <!--About Wood-->
    <section class="about-wood">
        <div class="container">
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100"
                    class="content-sec wow bounceInLeft">
                    <h1>About <span #about></span></h1>
                    <p #description></p>
                    <h4 class="txt-color" *ngIf="woods"><b>Wood Purpose</b> : {{productDetails?.wood_purpose_name}}</h4>
                    <h4 class="txt-color"*ngIf="productDetails?.price_condition == 1"><b>Price Range</b> : ₹
                        {{productDetails?.price_range_from}} - ₹ {{productDetails?.price_range_to}}</h4>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100"
                    class="img-sec text-center">
                    <img class="wow pulse" src="{{productDetails?.thumbnail_image}}" alt="wood" width="60%" />
                </div>
            </div>
            <!-- Sub wood images -->
            <h2 #imageHeading></h2>
            <div class="product-inner-images">
                <div class="product" *ngFor='let data of productDetails?.images'>
                    <img class="wow rollIn" src="{{data?.image}}" alt="wood" width="90%" />
                </div>
            </div>
        </div>
    </section>
    <!--Features-->
    <section class="features">
        <div class="container">
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100"
                    class="content-sec  wow bounceInLeft">
                    <h1 #features></h1>
                    <div *ngIf="woods">
                        <ul *ngFor='let data of productDetails?.feature'>
                            <li>{{data?.feature_name}}</li>
                        </ul>
                    </div>

                    <div *ngIf="products">
                     <h3 #whichSuits></h3>
                    </div>
                  
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100"
                    class="form-sec  wow bounceInRight">
                    <mat-card>
                        <form [formGroup]='woodForm'>
                            <div>
                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>Name <span class="required_symbol">*</span></mat-label>
                                    <input matInput formControlName='name'>
                                    <span *ngIf="woodForm.controls['name'].hasError('pattern')
                            && woodForm.controls['name'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid name'"
                                        [matTooltipDisabled]="!woodForm.controls['name'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="woodForm.controls['name'].hasError('required')
                            && (isSubmitted||woodForm.controls['name'].touched) " matSuffix [matTooltip]="'Please enter the name'"
                                        [matTooltipDisabled]="!woodForm.controls['name'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>




                                </mat-form-field>
                                <mat-form-field class="w49 " appearance="outline">
                                    <mat-label>Mobile No <span class="required_symbol">*</span></mat-label>
                                    <input matInput formControlName='mobileNo'>
                                    <span *ngIf="woodForm.controls['mobileNo'].hasError('pattern')
                            && woodForm.controls['mobileNo'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid mobie number'"
                                        [matTooltipDisabled]="!woodForm.controls['mobileNo'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="woodForm.controls['mobileNo'].hasError('required')
                            &&  (isSubmitted||woodForm.controls['mobileNo'].touched) " matSuffix
                                        [matTooltip]="'Please enter the mobile number'"
                                        [matTooltipDisabled]="!woodForm.controls['mobileNo'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                            </div>
                            <div>

                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>Email Id </mat-label>
                                    <input matInput formControlName='email'>
                                    <span *ngIf="woodForm.controls['email'].hasError('required')
                                    &&  (isSubmitted||woodForm.controls['email'].touched)" matSuffix
                                                [matTooltip]="'Please provide the email'"
                                                [matTooltipDisabled]="!woodForm.controls['email'].invalid"
                                                matTooltipClass="tooltip-red">
                                                <mat-icon color="warn" style="cursor: pointer;">info
                                                </mat-icon>
                                            </span>
                                    <span *ngIf="woodForm.controls['email'].hasError('email')
                            && woodForm.controls['email'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid email'"
                                        [matTooltipDisabled]="!woodForm.controls['email'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                   
                                </mat-form-field>
                                <mat-form-field class="w49 " appearance="outline">
                                    <mat-label>State  </mat-label>
                                    <mat-select formControlName='state'>
                                        <mat-option *ngFor="let state of allState" class="TextColor"
                                            (onSelectionChange)="getCity(state.state_id, $event?.isUserInput)"
                                            [value]="state.state_id">
                                            {{state.state_name}}
                                        </mat-option>
                                    </mat-select>
                                   

                                </mat-form-field>
                              
                            </div>
                            <div>
                                <mat-form-field class="w49 pr-10" appearance="outline">
                                    <mat-label>District  </mat-label>
                                    <mat-select formControlName='district'>
                                        <mat-option *ngFor="let district of cities" class="TextColor"
                                            [value]="district.city_id">
                                            {{district.city_name}}
                                        </mat-option>
                                    </mat-select>
                                  
                                </mat-form-field>
                                <mat-form-field class="w49" appearance="outline">
                                    <mat-label>Location </mat-label>
                                    <input matInput formControlName='Location'>
                                    <span *ngIf="woodForm.controls['Location'].hasError('pattern')
                                    && woodForm.controls['Location'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid location'"
                                        [matTooltipDisabled]="!woodForm.controls['Location'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                    
                                </mat-form-field>
                            </div>
                            <div class="Purpose">
                                <mat-label>Purpose of Enquiry</mat-label>
                            </div>
                            <div>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Product Name</mat-label>
                                    <input matInput  [value]="productDetails?.product_name" readonly>
                                </mat-form-field>
                            </div>
                            <div class="">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Notes                                    </mat-label>
                                    <textarea matInput formControlName='notes' row="5"></textarea>
                                    <input matInput formControlName='mobileNo'>
                                    <span *ngIf="woodForm.controls['notes'].hasError('pattern')
                            && woodForm.controls['notes'].touched" matSuffix
                                        [matTooltip]="'Please enter the valid notes'"
                                        [matTooltipDisabled]="!woodForm.controls['notes'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </mat-form-field>
                            </div>
                            <div *ngIf="productDetails?.fee > 0">
                                <div *ngIf="productDetails?.is_fee_applicable == 'Yes'">
                                    <mat-checkbox formControlName='isConsult'
                                      >Agree to Pay <span
                                            class="txt-color"><b>Rs.{{productDetails?.fee |slice : 0:3}}/</b></span> -
                                        Consultation
                                        Fee
                                    </mat-checkbox>
                                    <span *ngIf="woodForm.controls['isConsult'].hasError('required')
                                    && isSubmitted" matSuffix
                                        [matTooltip]="'Please agree to pay consultation fees'"
                                        [matTooltipDisabled]="!woodForm.controls['isConsult'].invalid"
                                        matTooltipClass="tooltip-red">
                                        <mat-icon color="warn" style="cursor: pointer;">info
                                        </mat-icon>
                                    </span>
                                </div>
                            </div>
                            
                            <div class="text-right">
                                <button type="button" class="primary-btn" (click)='create()'>Submit</button>
                            </div>
                        </form>
                    </mat-card>
                </div>
            </div>
        </div>
    </section>
    <div  class="whatsapp">
        <a  href="https://api.whatsapp.com/send?phone=+91 99 44 22 88 43&amp;text=Hello, Buywoods!" target="_blank">
            <img src="assets/images/whatsapp1.png" width="140" height="140">
        </a>
    </div>
    <div  class="call-now">
        <a  href="tel:+91 99 44 22 88 43">
            <img src="assets/images/call-2.gif" width="120" height="120">
        </a>
    </div>
    <app-footer></app-footer>
</div>