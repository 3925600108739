import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { fromEvent } from 'rxjs';
import { EnquiryService} from '../service/enquiry.service';
// import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { QuickViewComponent } from '../quick-view/quick-view.component';
import {MatDialog} from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AppLoaderService } from '../service/app-loader.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit,AfterViewInit{
  productList: any;
  overAllProduct: any;
  OverallProduct: any;
  OverallProductCount: any;
  dataSource: any;
  value=''
  searchData: any;
  searchValue: any;
  payload : any
  constructor(private modelService:EnquiryService,
    private Router:Router,
    private dialog: MatDialog,
    private title :Title,
    private loader : AppLoaderService){
  }
  
  

  ngOnInit(): void {
    this.title.setTitle('Buywoods | Product-List')
    this.OverallProductList()
  }

  SearchInput(e:any){
    this.searchValue=e.target.value
    this.OverallProductList()
  }

 OverallProductList(){
   this.modelService.getProducts(this.searchValue).subscribe( res =>{
    if(res.keyword == 'success'){
    this.OverallProductCount=res.count;
      this.OverallProduct=res.data; 
    }
   })
 }

 goActive(Data:any){
  window.scroll(0,0)
   console.log(Data);
   this.Router.navigateByUrl(`product-detail/${Data?.product_id}`)
   let formData= new FormData();
    formData.append('product_id', Data?.product_id)
    this.modelService.topProductSeen(formData).subscribe( res =>{
    })
 }
 openDialog(data:any) {
   this.payload=data;
   this.loader.open()
   this.modelService.getOne(data.product_id).subscribe((res:any)=>{
    if(res.keyword==='success'){
      this.loader.close()
      const dialogRef = this.dialog.open(QuickViewComponent,{
        width:'77%',
        panelClass:'slider',
        hasBackdrop: true,
        data :this.payload
      }); 
    }

   })
   
}

ngAfterViewInit(): void {
  // this.openDialog
}

}


