import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService} from '../service/api.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EnquiryService {
  getCities(state_id: any) {
    return this.modelService.get(`website/city?state_id=${state_id}`);
  }

  constructor(private modelService:ApiService){

  }

  topProductSeen(formData:any){
   return this.modelService.post('productvisit',formData)

  }

  create(formData:any):Observable<any>{
   return this.modelService.post('general/create',formData)
  }
  
  woodCreate(formData:any):Observable<any>{
    return this.modelService.post('productBased/create',formData)
   }
  productList():Observable<any>{
    return this.modelService.get('productname/list')
   }


  initialLoad(form:any){
       return this.modelService.post('visit_history',form)
  }

  

  getState(){
    return this.modelService.get('website/state')
}
   
  getWoodExpert(){
    return this.modelService.get('overAllWoodExpert/list')
}

  getProducts(data:any):Observable<any>{
    return this.modelService.get(`overAllProduct/list?searchWith=${data != undefined ? data : ''}`)
  }
  getOne(id:any):Observable<any>{
    return this.modelService.get(`productBased/view/${id}`)
  }


  getProductName():Observable<any>{
    return this.modelService.get(`productname/list`)
  }


  

}
