import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  constructor( private _vps: ViewportScroller) { }

  ngOnInit(): void {
  }
  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
}
}
